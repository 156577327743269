
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


const Seo = ({ title, description, lang, meta, imageUrl }) => {
    const { site, strapiAssets } = useStaticQuery(
        graphql`
       query {
         site {
           siteMetadata {
             title
             description
             siteUrl
           }
         }
         strapiAssets {
            defaultopengraph {
              localFile {
                childImageSharp {
                  fixed(jpegQuality: 100, width: 800) {
                    src
                  }
                }
              }
            }
          }
       }
     `
    )


    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    const ogImageUrl = site.siteMetadata.siteUrl + (imageUrl || strapiAssets.defaultopengraph.localFile.childImageSharp.fixed.src)


    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title + " - ai03 Design Studio"}
            // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title + " - ai03 Design Studio",
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: `@ai03_2725`,
                },
                {
                    name: `twitter:title`,
                    content: title + " - ai03 Design Studio",
                },
                {
                    property: `og:image`,
                    content: ogImageUrl,
                },
                {
                    property: `twitter:image`,
                    content: ogImageUrl,
                },
                {
                    property: `image`,
                    content: ogImageUrl,
                },
            ].concat(meta)}
        />
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default Seo