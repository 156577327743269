import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, Row } from 'react-bootstrap'

import MainLayout from '../../components/main-layout'
import Seo from '../../components/seo'
import HeaderBlock from '../../components/header-block'
import ContentCard from '../../components/card'


import { mainRow } from '../../styles/common.module.css'


const DesignerResourcesPage = ({ data }) => {

    return (
        <MainLayout pageTitle="Designer Resources">

            <Seo title="Designer Resources" description="Resources for keyboard designers" />

            <Container>
                <HeaderBlock header="Keyboard Designer Resources">
                    <p>For those who create.</p>
                </HeaderBlock>
                <Row xs={1} md={2} lg={3} className={`g-4 ${mainRow}`}>
                    {
                        data.allStrapiDesignerResource.nodes.map(node => (
                            <ContentCard key={node.id} cardTitle={node.title} cardDesc={node.description} cardLink={node.link} cardImg={node.cover.localFile} buttonText="Visit" externLink={true}></ContentCard>
                        ))
                    }
                </Row>
            </Container>


        </MainLayout>
    )
}

export const query = graphql`
query {
    allStrapiDesignerResource {
      nodes {
        id
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.618
                transformOptions: { fit: COVER, cropFocus: CENTER }
                placeholder: BLURRED
                width: 650
              )
            }
          }
        }
        title
        description
        link
      }
    }
  }
  
`

export default DesignerResourcesPage